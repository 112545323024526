import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ImageFragment } from '@codegen/cmsUtils';
import Image from '@ui/components/Image';
import { createTypography, mqMin } from '@ui/styles/base';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';

interface Props {
  connectVia: string[];
  travelTo: string[];
  vendorLogo?: Omit<ImageFragment, 'id'> | null;
  vendorName: string;
}

const TitleContainer = styled.h3(({ theme: { typography } }) =>
  createTypography(typography.body01),
);

const Container = styled.div(({ theme: { colours, spacings, typography } }) => [
  createTypography(typography.body01),
  css`
    display: flex;
    padding: ${spacings['16']}px;
    color: ${colours.text.default};
  `,
]);

const LabelContainer = styled.span(css`
  padding-right: 5px;
  font-weight: bold;
`);

const ImageContainer = styled.div(
  ({ theme: { spacings } }) => css`
    position: relative;
    display: none;
    height: 150px;
    flex: 0 0 250px;
    padding-right: ${spacings['16']}px;

    ${mqMin.Small} {
      display: block;
    }
  `,
);

const StyledImage = styled(Image)`
  width: auto;
  height: 100%;
`;

const InfoColumn = styled.div(
  ({ theme: { colours, spacings } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: ${spacings['16']}px;

    ${mqMin.Small} {
      border-left: 1px solid ${colours.border.default};
      margin-left: ${spacings['16']}px;
    }
  `,
);

const VendorCarouselCard = ({
  connectVia,
  travelTo,
  vendorLogo,
  vendorName,
}: Props) => {
  const { t } = useCmsTranslation();

  return (
    <Container>
      {vendorLogo && (
        <ImageContainer>
          <StyledImage
            alt={vendorLogo.alt}
            height={vendorLogo.height}
            objectFit="contain"
            src={vendorLogo.url}
            width={vendorLogo.width}
          />
        </ImageContainer>
      )}

      <InfoColumn>
        <TitleContainer>{vendorName}</TitleContainer>
        <div>
          <LabelContainer>{t('connect via', 'Connect via')}:</LabelContainer>
          <span>{connectVia.join(', ')}</span>
        </div>
        <div>
          <LabelContainer>{t('travel to', 'Travel to')}:</LabelContainer>
          <span>{travelTo.join(', ')}</span>
        </div>
      </InfoColumn>
    </Container>
  );
};

export default VendorCarouselCard;
